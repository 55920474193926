import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

export function formatDate(val, toFormat = 'DD MMM YYYY, HH:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss ZZ') {
  if (val) {
    return dayjs(String(val), fromFormat)
      .format(toFormat);
  }
}

export function addDaysToDate(val, days, fromFormat = 'YYYY-MM-DD HH:mm:ss ZZ') {
  if (val) {
    return dayjs(String(val), fromFormat)
      .add(days, 'day');
  }
}

export function timeFromNow(val) {
  if (val) {
    return dayjs(val).fromNow();
  }
}

export function isOnline(val, minutesToAdd = 5) {
  if (val) {
    return (dayjs(val).valueOf() + dayjs(minutesToAdd * 60000)) > dayjs().valueOf();
  }
  return false;
}
