<template>
  <transition name="slideUpDown">
    <div>
      <div class="dashboard-page search-page">
        <div class="panel panel--search mt-4">
          <div class="panel-heading pb-1">
            <div>
              <h3>{{ $t('companies-searches') }}</h3>
              <div class="jumbotron jumbotron__search-history">
                <div class="content mb-2">
                  <div class="left">
                    <i class="material-icons md-36">info_outline</i><br />
                  </div>
                  <div class="right">
                    {{ $t('public-search-history-description') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="search-body">
            <PublicSearchHistoryForm />
            <PublicSearchHistoryTable :publicSearchHistory="publicSearchHistory"
                                      :publicSearchHistoryMeta="publicSearchHistoryMeta"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import PublicSearchHistoryTable from './profile-neoworqer/PublicSearchHistoryTable';
import PublicSearchHistoryForm from './profile-neoworqer/PublicSearchHistoryForm';

export default {
  i18nOptions: { namespaces: 'search-history' },
  components: {
    PublicSearchHistoryTable,
    PublicSearchHistoryForm,
  },
  mounted() {
    this.$store.dispatch('searchHistory/getPublicSearchHistory');
  },
  computed: {
    ...mapState({
      publicSearchHistory: state => state.searchHistory.publicSearchHistory,
      publicSearchHistoryMeta: state => state.searchHistory.publicSearchHistoryMeta,
      profile: state => state.profile.profile,
    }),
    total() {
      if (this.publicSearchHistoryMeta) return this.publicSearchHistoryMeta.total;
      return '';
    },
  },
  methods: {
    bulkUpdateSearchHistory(state) {
      this.$store.dispatch('searchHistory/bulkUpdateSearchHistory', state);
    },
  },
};
</script>
