<template>
  <div class="search-form p-0 no-border">
    <div class="search-form__content">
      <form
            @submit.prevent="getPublicSearchHistory"
            class="search-form__content"
            novalidate
        >
          <AppInput
              class="mb-2"
              v-model="publicSearchHistoryForm.job_title"
              :label="$t('job-title')"
              :placeholder="$t('example-job')"
          />
          <AppSelect
              class="mb-2"
              v-model="publicSearchHistoryForm.location"
              :label="$t('location')"
              :options="states ? states : []"
              :placeholder="$t('select') + ' ' +$t('location').toLowerCase()"
              :allowEmpty="true"
          />
          <AppSelect
              class="mb-2"
              v-model="publicSearchHistoryForm.work_experience"
              :label="$t('experience')"
              :options="experiences ? experiences : []"
              :placeholder="$t('select') + ' ' + $t('experience').toLowerCase()"
              :allowEmpty="true"
          />
          <AppGroupSelect
              class="mb-2"
              v-model="publicSearchHistoryForm.skill"
              :options="skills ? skills : []"
              :placeholder="$t('select') + ' ' + $t('skill').toLowerCase()"
              :labelText="$t('skill')"
              field="skills"
              trackBy="name"
              label="name"
              :multiple="false"
              :errors="errors"
              :searchParam="'skills/getAllSkills'"
              :allowEmpty="true"
          />
          <div class="search-form__buttons">
            <button
                type="submit"
                class="btn btn-block btn-primary"
                :class="{ 'pulsate': animateBtn }"
            >
              {{ $t("search") }}
            </button>
            <button
                type="button"
                class="btn btn-block btn-primary-white-outline"
                @click="clearForm"
            >
              {{ $t("clear") }}
            </button>
          </div>
        </form>
    </div>
    <AppError v-if="errors" :errors="errors" :field="field"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { tooltip } from 'uiv';
import AppSelect from '../forms/AppSelect';
import AppGroupSelect from '../forms/AppGroupSelect';
import AppInput from '../forms/AppInput';
import AppError from '../AppError';
import Errors from '../../utils/errors';
import { formatDate } from '../../utils/date.filter';

export default {
  name: 'search-history-form',
  i18nOptions: { namespaces: 'search-history' },
  components: {
    AppSelect,
    AppGroupSelect,
    AppInput,
    AppError,
  },
  directives: {
    tooltip,
  },
  filters: {
    date: formatDate,
  },
  data() {
    return {
      errors: new Errors(),
      currency: this.$appConfig.currency,
      experiences: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.reloadExperiences();
    }, 1000);
  },
  computed: {
    ...mapState({
      animateBtn: state => state.searchHistory.animateBtn,
      publicSearchHistoryForm: state => state.searchHistory.publicSearchHistoryForm,
      loading: state => state.searchHistory.loading,
      skills: state => state.skills.skills,
      states: state => state.countries.states,
      language: state => state.language.lang,
    }),
    field() {
      const error = this.errors[Object.keys(this.errors)[0]];
      if (error) return Object.keys(error)[0];
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    language: function (newVal, oldVal) {
      if (newVal && oldVal) {
        setTimeout(() => {
          this.reloadExperiences();
        }, 0);
      }
    },
  },
  methods: {
    reloadExperiences() {
      this.experiences = [];
      this.experiences = [
        { name: this.$t('any'), id: 0 },
        { name: `< 1 ${this.$t('year')}`, id: 1 },
        { name: `> 1 ${this.$t('year')}`, id: 2 },
        { name: `> 2 ${this.$t('years')}`, id: 3 },
        { name: `> 3 ${this.$t('years')}`, id: 4 },
        { name: `> 5 ${this.$t('years')}`, id: 5 },
      ];
    },
    getPublicSearchHistory() {
      this.errors.clear();
      this.$store.commit('searchHistory/animateBtn', false);
      this.$store.commit('searchHistory/loading', true);
      this.$store.dispatch('searchHistory/getPublicSearchHistory').then((res) => {
        console.log(res);
      }).catch((err) => {
        this.errors.set(err.response.data.errors);
      })
        .finally(() => {
          this.$store.commit('search/loading', false);
        });
    },
    clearForm() {
      this.$store.commit('searchHistory/resetPublicSearchHistoryForm');
    },
  },
};
</script>
