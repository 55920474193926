<template>
  <div class="table-view">
    <table class="table table-default table-custom">
      <thead>
        <tr>
          <th>
           #
          </th>
          <th>
            <div class="heading-table">
              {{ $t("company") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("location") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("job-title") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("skills") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("start-date") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("end-date") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("experience") }}
            </div>
          </th>
          <th>
            <div class="heading-table">
              {{ $t("type") }}
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="publicSearchHistory.length" class="search-table-body">
        <tr v-for="(i, index) in publicSearchHistory" :key="i.id" class="striped">
          <td v-if="publicSearchHistoryMeta">
            {{publicSearchHistoryMeta.from > 1 ? totalResults - publicSearchHistoryMeta.from - index + 1 : totalResults - index }}
          </td>
          <td v-if="i.company" class="d-flex">
            <router-link :to="'/public-company/' + i.company.uuid" class="avatar-wrapper" target="_blank">
              <div class="avatar">
                <img :src="i.company.company_logo" :alt="i.company.name | initials" />
              </div>
              <h5>{{ i.company.name }}</h5>
            </router-link>
            <button
              v-if="i.role"
              v-popover="{ html: 1, title: $t('job-information'), content: popOverContent(i.role) }"
              type="primary"
              class="d-inline-block btn-designless no-border text-primary-light"
              >
              <i class="material-icons md-18" aria-hidden="true">badge</i>
            </button>
          </td>
          <td v-else>-</td>
          <td>{{i.state ? i.state.name : ''}}</td>
          <td>{{i.job_title}}</td>
          <td>{{i.skills}}</td>
          <td>{{i.start_date | date("DD MMM YYYY", "YYYY-MM-DD")}}</td>
          <td>{{i.end_date | date("DD MMM YYYY", "YYYY-MM-DD")}}</td>
          <td>{{i.work_experience > 0 ? $t(`experience-${i.work_experience}`): ''}}</td>
          <td>{{i.personality_type}}</td>
          <td>
            <div class="action-icons-row">
              <div class="row-item">
                <button class="btn btn-sm btn-primary-light"
                  v-if="!i.is_applied"
                  :disabled="loading"
                  @click="applyToSearchHistory(i.id)">
                    {{ $t('apply') }}
                </button>
                <button class="btn btn-sm btn-danger"
                  v-else
                  :disabled="loading"
                  @click="confirmDeclineApplication(i.id)">
                    {{ $t('decline') }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="10">
            <h4>{{ $t('there-are-no-records') }}</h4>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="publicSearchHistoryMeta" class="d-flex justify-content-center pt-3">
      <Pagination v-model="publicSearchHistoryMeta.current_page" :total-page="publicSearchHistoryMeta.last_page" @change="changePage" align="center" />
    </div>
  </div>
</template>

<script>
import { popover, tooltip, Pagination } from 'uiv';
import { userInitials } from '../../utils/helpers';
import { formatDate } from '../../utils/date.filter';
import ModalService from '../../services/modal.service';

export default {
  name: 'search-history-table',
  i18nOptions: { namespaces: 'search-history' },
  components: {
    Pagination,
  },
  directives: {
    tooltip,
    popover,
  },
  filters: {
    initials: userInitials,
    date: formatDate,
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, ' ');
    },
  },
  data() {
    return {
      currency: this.$appConfig.currency,
      multiChecked: false,
      loading: false,
    };
  },
  props: ['publicSearchHistory', 'publicSearchHistoryMeta'],
  computed: {
    totalResults() {
      return this.publicSearchHistoryMeta.total ? this.publicSearchHistoryMeta.total : 0;
    },
  },
  methods: {
    popOverContent(data) {
      let html = '';
      html += `${this.$t('job-title')}: ${data.job_title}\n`;
      html += `${this.$t('experience')}: ${data.work_experience ? this.$t(`experience-${data.work_experience}`) : '-'}\n`;
      html += `${this.$t('type')}: ${data.personality_type}\n`;
      html += `${this.$t('start-date')}: ${this.$options.filters.date(data.start_date, 'DD MMM YYYY', 'YYYY-MM-DD')}\n`;
      html += `${this.$t('end-date')}: ${this.$options.filters.date(data.end_date, 'DD MMM YYYY', 'YYYY-MM-DD')}\n`;
      html += `${this.$t('description')}: ${this.$options.filters.strippedContent(data.description)}`;
      return html;
    },
    changePage($event) {
      this.$store.dispatch('searchHistory/getPublicSearchHistory', $event);
    },
    applyToSearchHistory(searchEntryId) {
      this.$store.commit('searchHistory/applymentDataProps', { search_entry_id: searchEntryId });
      this.$store.commit('searchHistory/isMotivationalLetterModalOpen', true);
    },
    confirmDeclineApplication(searchEntryId) {
      const content = this.$t('are-you-sure');
      ModalService.confirm({
        content: content,
        cancelType: 'link',
        okText: this.$t('yes'),
        customClass: 'modal-confirm',
        cancelText: this.$t('cancel'),
      })
        .then(() => {
          this.declineApplication(searchEntryId);
        })
        .catch(() => {
        });
    },
    declineApplication(searchEntryId) {
      this.$store.dispatch('searchHistory/declineApplication', searchEntryId);
    },
  },
};
</script>
